export interface IGetProductListPayload {
  channel: string;
  locationType: string;
  searchString: string;
  pincode: string;
  storeAliasId?: string;
  cursor?: number | string;
  pageSize: number;
  filters?: IProductFilterValues[];
  debugEsq?: string;
  queryParams?: string;
  rankingStrategy?: string;
  searchType?: string;
}

export interface IGetAutoSuggestPayload {
  searchString: string,
  pincode: string,
}

interface ITaxonomy {
  level: number;
  name: string;
}

interface IUom {
  id: number;
  name: string;
  qtyFactor: number;
  decimalPrecision: number;
}
interface Attribute {
  name: string;
  value: string;
}

export interface ProductAttribute {
  attributes: Attribute[];
  defaultAttributeListSize: number;
  name: string;
}
interface Taxonomy {
  id: number;
  level: number;
  name: string;
}

export interface PricingDetails {
  lg: number;
  maxPrice: boolean
  minPrice: boolean
  suggestedPrice: boolean
  variablePrice: boolean
  locationName: string
}
export interface IProduct {
  baseUom: {
    decimalPrecision: number;
    id: number;
    name: string;
    qtyFactor: number;
  };
  brandName: string;
  hsnCode: string;
  imageUrl: string;
  isServiceable: boolean;
  isVariant: boolean;
  name: string;
  productAttributes: ProductAttribute[]
  pricing: {
    priceRange: {
      end: number;
      start: number;
    };
    variantPrice: number;
  };
  productId: number;
  sku: string;
  taxRate: number;
  taxonomy: Taxonomy[];
  variantCount: number;
  variantId: number;
  variantSkus: string[];
  description: string;
  price: PricingDetails[];
}

export class ProductList {
  productList: Array<IProduct> = [];

  constructor(productList: Array<IProduct>) {
    this.productList = productList;
  }
}

export interface IProductFilterValues {
  name: string;
  type: string;
  productCategoryAliasIds?: string[];
  productBrandAliasIds?: string;
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<IProduct>,
  payloadList: Array<IProduct>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
